<template>
  <div class="el-parameter-editor-item">
    <h1>{{ title }} ({{ replacerName }})</h1>
    <el-form inline label-position="top" class="el-parameter-editor-item-form" :model="updated_values" ref="updated_values" :rules="rules">
      <el-row :gutter="20" class="el-parameter-editor-item-form-row">
        <el-col>
          <el-form-item prop="minValue" label="Минимальное значение">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.minValue"
              placeholder="Введите минимальное значение"
              @change="onChange"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="maxValue" label="Максимальное значение">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.maxValue"
              placeholder="Введите максимальное значение"
              @change="onChange"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="step" label="Шаг">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.step"
              placeholder="Введите шаг"
              @change="onChange"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="defaultValue" label="Значение по умолчанию">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.defaultValue"
              placeholder="Введите значение по умолчанию"
              @change="onChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item prop="isClientEditable">
            <el-checkbox
              size="small"
              true-label=1
              false-label=0
              :checked="updated_values.isClientEditable==1"
              v-model="updated_values.isClientEditable"
              @change="onChange"
            >Доступно клиенту для редактирования</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>

import {required, validatorNumber} from "@/core/helpers/validators"

export default {
  name: "RangeEditor",
  data() {
    return {
      updated_values: {
      },
      rules: {
        minValue: [required(), validatorNumber],
        maxValue: [required(), validatorNumber],
        defaultValue: [required(), validatorNumber],
        step: [required(), validatorNumber],
      }
    }
  },
  props: {
    title: {
      type: String,
      default: "Заголовок",
    },
    replacerName: {
      type: String,
      default: "#replacer",
    },
    values: {
      type: Object,
      default: function() {},
    },
  },
  created() {
    this.updated_values = this.values;
  },
  mounted() {
    this.$refs['updated_values'].validate();
  },
  beforeDestroy() {
    this.onDelete();
  },
  methods: {
    onChange() {
      this.$emit("updateValues", {
        replacer: this.replacerName,
        available_values: {
          min_value: this.updated_values.minValue,
          max_value: this.updated_values.maxValue,
          step: this.updated_values.step,
        },
        defaultValue: this.updated_values.defaultValue,
        is_client_editable: this.updated_values.isClientEditable,
      });
    },
    onDelete() {
      this.$emit("removeEditor", {
        replacer: this.replacerName,
      });
    },
  },
};
</script>

<style>
div .el-parameter-editor-item {
  border: thin solid #3883fa;
  margin-top: 5px;
  padding: 0 10px;
}

.el-parameter-editor-item-form {
  max-width: 100%;
}

.el-parameter-editor-item-form-row {
  display: flex;
  justify-content: space-around;
}
</style>
