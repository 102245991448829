<template>
  <div class="el-parameter-editor-item">
    <h1>{{ title }} ({{ replacerName }})</h1>
    <el-form inline label-position="top" class="el-parameter-editor-item-form"  :model="updated_values" ref="updated_values" :rules="rules">
      <el-row :gutter="20" class="el-parameter-editor-item-form-row">
        <el-col>
          <el-form-item prop="disabledValue" label="Выключенное значение" size="small">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.disabledValue"
              placeholder="Введите выключенное значение"
              @change="onChange"
            >
              <el-button slot="append" icon="el-icon-turn-off" style="color: red;"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="activeValue" label="Включенное значение" size="small">
            <el-input
              :controls="false"
              size="small"
              v-model="updated_values.activeValue"
              placeholder="Введите включенное значение"
              @change="onChange"
            >
              <el-button slot="append" icon="el-icon-open" style="color: green;"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item v-if="showDefaultValueOptions()" prop="defaultValue" label="Значение по умолчанию" size="small">
              <el-radio-group
                v-model="updated_values.defaultValue"
                style="margin-top: -3px; margin-left: 65px;"
                @change="onChange"
              >
                <el-radio-button :label="updated_values.disabledValue"></el-radio-button>
                <el-radio-button :label="updated_values.activeValue"></el-radio-button>
              </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item prop="isClientEditable">
            <el-checkbox
              size="small"
              true-label=1
              false-label=0
              :checked="updated_values.isClientEditable==1"
              v-model="updated_values.isClientEditable"
              @change="onChange"
            >Доступно клиенту для редактирования</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>

import {required} from "@/core/helpers/validators"

export default {
  name: "SwitchEditor",
  data() {
    return {
      updated_values: {
      },
      rules: {
        activeValue: [required()],
        disabledValue: [required()],
        defaultValue: [required()],
      }
    }
  },
  props: {
    title: {
      type: String,
      default: "Заголовок",
    },
    replacerName: {
      type: String,
      default: "#replacer",
    },
    values: {}
  },
  created() {
    this.updated_values = this.values;
    
  },
  mounted() {
    this.$refs['updated_values'].validate();
  },
  beforeDestroy() {
    this.onDelete();
  },
  methods: {
    onChange() {
      this.$emit("updateValues", {
        replacer: this.replacerName,
        available_values: {
          active_value: this.updated_values.activeValue,
          disabled_value: this.updated_values.disabledValue,
        },
        defaultValue: this.updated_values.defaultValue,
        is_client_editable: this.updated_values.isClientEditable
      });
    },
    onDelete() {
      this.$emit("removeEditor", {
        replacer: this.replacerName,
      });
    },
    showDefaultValueOptions() {
      return !(
           this.updated_values.disabledValue === null 
        || this.updated_values.activeValue === null
        || this.updated_values.disabledValue === "" 
        || this.updated_values.activeValue === ""
        )
    }
  },
};
</script>

<style>
div .el-parameter-editor-item {
  border: thin solid #3883fa;
  margin-top: 5px;
  padding: 0 10px;
}

.el-parameter-editor-item-form {
  max-width: 100%;
}

.el-parameter-editor-item-form-row {
  display: flex;
  justify-content: space-around;
}
</style>
