import {
  required,
  length,
  validatorResolution,
  validatorNumberMin,
  validatorNumberInRange,
} from "@/core/helpers/validators";

export const defaultArrayValidate = [
  required(),
  length(1, 200)
]

export default {
  data () {
    return {
      loading: false,
      form: {
        name: '',
        main_stream_template: '',
        main_stream_resolution: '',
        main_stream_bandwidth: '',
        sub_stream_template: '',
        sub_stream_resolution: '',
        transcode_video: 0,
        transcode_audio: 0,
        is_onvif: 0,
        is_ptz: 0,
        is_wifi: 0,
        port_onvif_default: 80,
        url_onvif_template: '',
        onvif_media_profile_token: 'profile0',
        id_sidetask_setting: 1,
        has_custom_sound_settings: 0,
        custom_sound_settings: null,
      },
      rules: {
        name: [
          required(),
          length(1, 100)
        ],
        main_stream_template: [
          required(),
          length(1, 200)
        ],
        main_stream_resolution: [
          required(),
          length(1, 200),
          { ...validatorResolution }
        ],
        main_stream_bandwidth: [
          required('number')
        ],
        sub_stream_resolution: [
          length(1, 200),
          { ...validatorResolution }
        ],
        description: [
          length(0, 200)
        ],
        id_sidetask_setting: [
          required("number"),
          validatorNumberMin(1),
        ],
        has_custom_sound_settings: [
          validatorNumberInRange(0, 1),
        ],
        custom_sound_settings: [
          length(0, 512),
        ]
      },
      bandwidth: {
        mainWidth: '',
        mainHeight: '',
        subWidth: '',
        subHeight: ''
      }
    }
  },
  methods: {
    concatMainBandwidth () {
      const {
        mainWidth,
        mainHeight
      } = this.bandwidth

      const result = `${mainWidth || ''}x${mainHeight || ''}`

      this.form.main_stream_resolution = result.length > 1 ? result : ''
    },
    concatSubBandwidth () {
      const {
        subWidth,
        subHeight
      } = this.bandwidth

      const result = `${subWidth || ''}x${subHeight || ''}`

      this.form.sub_stream_resolution = result.length > 1 ? result : ''
    }
  }
}
