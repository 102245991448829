<template>
  <div class="el-parameter-editor-item">
    <h1>{{ title }} ({{ replacerName }})</h1>
    <el-form class="el-parameter-editor-item-form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Выберите возможные значения" prop="id">
            <el-select
              v-model="selected_values"
              multiple
              size="small"
              placeholder="Выберите значение"
              style="width: 100%"
              filterable
              @change="onChange"
            >
              <el-option
                v-for="enumVal in allItems"
                :key="enumVal.id"
                :label="enumVal.text"
                :value="enumVal"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Выберите значение по умолчанию" prop="id">
            <el-select
              v-model="default_value"
              size="small"
              placeholder="Выберите значение"
              style="width: 100%"
              filterable
              @change="onChange"
            >
              <el-option
                v-for="enumVal in selected_values"
                :key="enumVal.id"
                :label="enumVal.text"
                :value="enumVal"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item prop="isClientEditable">
            <el-checkbox
              size="small"
              true-label=1
              false-label=0
              :checked="is_client_editable==1"
              v-model="is_client_editable"
              @change="onChange"
            >Доступно клиенту для редактирования</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "EnumEditor",
  model: {
    prop: "item-id",
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: "Заголовок",
    },
    allItems: {
      type: Array,
      default: function() {},
    },
    selectedValues: {
      type: Array,
      default: function() {},
    },
    defaultValue: {
      type: Object,
      default: function() {
        return {
          id: null,
          text: null,
          value: null,
        };
      },
    },
    isClientEditable: {
      type: Number,
      default: 1
    },
    replacerName: {
      type: String,
      default: "#replacer",
    },
  },
  data() {
    return {
      selected_values: this.selectedValues,
      default_value: this.defaultValue,
      is_client_editable: this.isClientEditable
    };
  },
  created() {
    this.onChange();
  },
  beforeDestroy() {
    this.onDelete();
  },
  methods: {
    onChange() {
      this.$emit("updateValues", {
        replacer: this.replacerName,
        values: this.selected_values,
        default_value: this.default_value,
        is_client_editable: this.is_client_editable
      });
    },
    onDelete() {
      this.$emit("removeEditor", {
        replacer: this.replacerName,
      });
    },
  },
};
</script>

<style>
div .el-parameter-editor-item {
  border: thin solid #3883fa;
  margin-top: 5px;
  padding: 0 10px;
}

.el-parameter-editor-item-form {
  max-width: 100%;
}
</style>
