<template>
  <el-container>
    <main class="tab__container">
      <h2 class="page__title">
        Редактирование модели
      </h2>
      <el-tabs>
        <el-tab-pane v-for="(tab, i) in components" :label="tab.name" :key="i">
          <component v-bind="tab.props" :is="tab.component" />
        </el-tab-pane>
      </el-tabs>
    </main>
  </el-container>
</template>

<script>
import form from '../../mixins/form'
import ModelFormEditMain from "./FormEdit";
import ModelFormEditConfigurations from "./FormConfigurations";

export default {
  name: 'model-form-edit',
  mixins: [form],
  components: { ModelFormEditMain, ModelFormEditConfigurations },
  data() {
    const tabs = [
      {
        name: "Основные",
        component: ModelFormEditMain,
        props: {},
      },
      {
        name: "Конфигурация",
        component: ModelFormEditConfigurations,
        props: {},
      },
    ];
    return {
      components: tabs,
    };
  },
  methods: {
    submit (event) {
      event.preventDefault()

      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;

          this.updateModel({
            id: this.id,
            data: this.form
          })
            .then(() => {
              this.$message({
                message: 'Информация обновлена',
                type: 'success'
              })
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: e.response.data.error || "Неизвестная ошибка",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }

        return valid;
      })
    },
  }
}
</script>

<style lang="scss">
.page_container {
  padding: 20px;
}
.bandwidth, .portonvifinput {
  width: 120px;
  padding: 0 10px
}
.bandwidth, .portonvifinput > input {
  text-align: center;
}

.tab__container {
  width: 100%;
  height: calc(100vh - 60px);
  padding: 20px 20px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  .el-tabs {
    height: calc(100% - 63px);
    .el-tabs__content{
      height: calc(100% - 47px);
      .el-tab-pane {
        height: 100%;
        .tab {
          height: 100%;
          & .tab__footer + .page_container {
            height: calc(100% - 60px);
          }
        }
      }
    }
  }
  h2 {
    font-size: 20px;
  }
}
</style>
