<template>
  <div>
    <el-select
      placeholder="Выбрать тип"
      size="small"
      :value="$props.selected"
      filterable
      style="width: 100%"
      :loading="loading"
      loading-text="Загрузка списка..."
      @change="onChange"
    >
      <el-option
        v-for="i in sideTaskTypes"
        :key="i.id_sidetask_setting"
        :value="i.id_sidetask_setting"
        :label="i.name"
      />
    </el-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SelectModelSideTaskType",
  props: {
    selected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("sideTaskTypes", {
      sideTaskTypes: "getSideTaskTypes",
    }),
  },
  mounted() {
    this.loading = true;
    this.fetchSideTaskTypes()
      .then(() => {
        this.onChange(this.selected);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions("sideTaskTypes", {
      fetchSideTaskTypes: "FETCH_SIDE_TASK_TYPES",
    }),
    onChange(value) {
      this.$emit(
        "change",
        this.sideTaskTypes.find((e) => e.id_sidetask_setting === value)
      );
    },
  },
};
</script>
